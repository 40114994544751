
import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {
  CaseContact,
  CompanyContact,
  EmailTemplate,
  LoadBaseModal,
  LoadEntity,
  LoadPanel,
  LoadTagModal
} from "@/core/composite/composite";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {onUpdated} from "@vue/runtime-core";
import CaseStatus from "@/views/case/CaseStatus.vue";
import EntityView from "@/components/base/common/EntityView.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import MenuWatch from "@/views/metaEntity/MenuWatch.vue";
import WatchAlert from "@/views/metaEntity/WatchAlert.vue";
import CaseService from "@/core/services/CaseService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import HasOtherCases from "@/views/case/HasOtherCases.vue";
import FormNote from "@/views/activity/FormNote.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import Warnings from "@/views/note/Warnings.vue";
import FormMoveCase from "@/views/case/FormMoveCase.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import Froala from "@/components/base/editor/Froala.vue";
import AttachmentFileButton from "@/components/base/upload/AttachmentFileButton.vue";
import PageService from "@/core/services/PageService";
import store from "@/store";
import CMStatus from "@/views/case/CMStatus.vue";
import CaseManagementForm from "@/views/case/CaseManagementForm.vue";
import Duration from "@/views/activity/Duration.vue";
import MenuLink from "@/components/base/action/MenuLink.vue";
import TagEntities from "@/views/tag/TagEntities.vue";
import ScanEventMenu from "@/views/scan/ScanEventMenu.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";


export default defineComponent({
  name: "Case",
  components: {
    DateTimeFormat,
    ScanEventMenu,
    TagEntities,
    MenuLink,
    Duration,
    CaseManagementForm,
    CMStatus,
    AttachmentFileButton,
    Froala,
    FormErrorMessage,
    BaseRadio,
    BaseSelect,
    BaseForm,
    FormMoveCase,
    Warnings,
    BaseModal,
    FormNote,
    HasOtherCases,
    EntityLoading,
    WatchAlert,
    MenuWatch, Avatar, TabNavigate, CaseStatus, QuickAction, EntityView
  },
  setup() {
    const noteModel = ref({text: '', cazeId: ''});
    const emailRequest = ref({subject: '', body: '', type: 'PATIENT', emailTo: '', docs: ''})
    const attachments = ref<any[]>([])
    const files = ref<any>([]);
    const submitting = ref(false);
    const store = useStore();
    const caze = computed<any>(() => store.state.CaseModule.case)
    const entityLoading = computed(() => store.state.LoadingModule.entityLoading);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Case Details', [
        {link: true, router: '/policies/' + caze.value?.policy?.id, text: 'View Policy'},
        {link: false, router: '', text: 'View Case'}
      ])
    })
    return {
      ...LoadEntity(Actions.LOAD_CASE),
      caze,
      ...LoadPanel(),
      entityLoading,
      noteModel,
      ...LoadBaseModal(),
      ...EmailTemplate(),
      ...CompanyContact(''),
      ...CaseContact(''),
      emailRequest,
      attachments,
      submitting,
      files,
      ...LoadTagModal(),
    }
  },
  emits: ['statusUpdate'],
  methods: {
    updateStatus(status) {
      CaseService.updateStatus(this.caze.id, {status: status}).then(() => {
        this.reloadData()
        this.$emit('statusUpdate', this.caze.id)
      })
    },
    addWarning() {
      this.noteModel.text = ''
      this.noteModel.cazeId = this.caze.id
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.showBaseModal()
    },
    moveCase() {
      const baseModal = this.$refs.formMoveCaseRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    onMovedCase() {
      this.reloadData()
      const baseModal = this.$refs.formMoveCaseRef as typeof BaseModal
      baseModal.hideBaseModal()
    },
    onSaveWarning() {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal();
      const warning = this.$refs.warningsRef as typeof Warnings
      warning.paginationLoad();
    },
    buildNavItems(caze: any) {
      let items = [
        {id: 'main-overview', router: `/cases/` + caze.id, name: 'Overview'},
      ]
      if (caze.access.showReserve) {
        items.push({id: 'case-reserve', router: `/cases/` + caze.id + `/reserve`, name: 'Reserve'})
      }
      items.push({id: 'case-providers', router: `/cases/` + caze.id + `/providers`, name: 'Providers'})
      if (caze.cmStatus === 'ACTIVE' || caze.cmStatus === 'CLOSED') {
        items.push({
          id: 'document-cases',
          router: `/cases/` + caze.id + `/case-management/summary`,
          name: 'Case Management'
        },)
      }
      items.push({id: 'case-activities', router: `/cases/` + caze.id + `/activities`, name: 'Activities'},
        {id: 'document-cases', router: `/cases/` + caze.id + `/documents`, name: 'Documents'},
        {id: 'case-tasks', router: `/cases/` + caze.id + `/tasks`, name: 'Tasks'},
        {id: 'case-reminders', router: `/cases/` + caze.id + `/reminders`, name: 'Reminders'},
        {id: 'case-fees', router: `/cases/` + caze.id + `/fees`, name: 'Fees'},
        {id: 'case-process', router: `/cases/` + caze.id + `/processes`, name: 'Processes'}
      )
      items.push({id: 'caseAnalytics', router: `/cases/` + caze.id + `/analytics`, name: 'Analytics'})
      return items;
    },

    onContactSelect(email) {
      this.emailRequest.emailTo = email;
    },
    onTemplateSelect(id) {
      this.submitting = true;
      const template = this.templates.find(item => item.id === id);
      if (template) {
        PageService.files(template.id).then(res => {
          this.files = res;
        })
        this.emailRequest.body = template.body;
        this.emailRequest.subject = template.name;
      } else {
        this.emailRequest.body = '';
        this.emailRequest.subject = '';
      }
      CaseService.emailFormat(this.caze.id, this.emailRequest).then(res => {
        this.emailRequest.body = res.body
      }).finally(() => {
        this.submitting = false;
      })
    },
    onRemove(index) {
      this.attachments.splice(index, 1);
    },
    onRemoveFile(index) {
      this.files.splice(index, 1);
    },
    onSendEmail() {
      this.loadCaseContact(this.caze.id)
      this.loadCompanyContact(this.caze.client.id);
      const baseModal = this.$refs.sendClaimAckRef as typeof BaseModal
      baseModal.showBaseModal()
      this.emailRequest.emailTo = this.caze.email
      this.attachments = [];
    },
    onSubmitSend() {
      this.submitting = true;
      this.emailRequest.docs = this.files.map(item => item.id).join(",");
      CaseService.sendAck(this.caze.id, this.emailRequest, this.attachments).then(() => {
        this.submitting = false
        const baseModal = this.$refs.sendClaimAckRef as typeof BaseModal
        baseModal.hideBaseModal()
      }).finally(() => {
        this.submitting = false;
      })
    },
    onSelectedFile(file) {
      this.attachments.push(file)
    },
    activeCM() {
      const modal = this.$refs.caseManagementRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onUpdated(caze) {
      store.commit(Mutations.SET_CASE, caze);
      if (caze.cmStatus === 'NOT_REQUIRED') {
        this.$router.push({
          name: 'caseOverview',
          params: {id: caze.id}
        })
      }
      const modal = this.$refs.caseManagementRef as typeof BaseModal;
      modal.hideBaseModal();
    }
  }
})
